/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap';

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import './assets/styles/globals';

.sb-base-color {
  color: $stateBarBlue;
}

.sbGridHeader {
  background-color: $stateBarBlue;
  color: white;
  font-weight: 600;
}

.sb-banner-header {
  color: $stateBarBlue;
  font-size: 25px;
  font-weight: 500;
}

.sb-grid-header-template {
  height: 100%;
  word-wrap: break-word;
  white-space: normal;
  text-align:center;
}

.dx-header-row {
  background-color: $stateBarBlue;
  color: white;
  font-weight: 600;

  .dx-datagrid-action {
    // text-align: center !important;
    vertical-align: middle !important;
  }

}

.app-radio-group-top .dx-radio-value-container {
  vertical-align: top;
}
