$stateBarBlue: #003366;

.sb-container-white {
    width: 100%;
    background-color: white;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.10), 0 3px 5px 0 rgba(0, 0, 0, 0.10);
    padding: 10px;
  }

  .sb-box-white {
    width: 100%;
    background-color: white;
    border: 1px lightgray solid;
    border-radius: 10px;
    padding: 10px;
  }


.sb-flex-container {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
}

  .sb-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .sb-flex-row-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .sb-flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .sb-flex-row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .sb-flex-row-space-evenly {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .sb-flex-column {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }
